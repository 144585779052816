import React, { useEffect, Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { Link, LinkProps, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cloneDeep } from 'lodash';
import { Page, PagesList } from 'store/settings/layout';
import { AppState } from 'store/rootReducer';
import { getRewardsTabStatus } from 'store/rewards/selectors';
import { RewardsTabStatus } from 'store/rewards/types';
import { EmailMarketingCustomerSettings, getEmCustomerSettings } from 'store/emailMarketing/settings';
import {
  LeftMenuStates,
  getActivePage,
  getLeftSideMenuState,
  openLeftHandSideMenu,
  isSupportSearch,
  getMenuPagesWithFiltration
} from 'store/navigation';
import { ToolTip } from 'shared-components/Hint';
import {
  deviceMedia,
  leftMenuIconContainerWidth,
  leftMenuPaddingForTextContainer,
  leftMenuTextContainerWidth,
  supportSearchPathname
} from 'appConstants';
import { googleAnalytics } from 'services/googleAnalytics';
import { getLocations, LocationsList } from 'store/settings';
import { generateDataTestId } from 'services/utils';
import { DEFAULT_NS } from 'appConstants/translationNamespaces';
import { useTranslation } from 'react-i18next';
import { getColor } from '../theme';
import { textSubtitle, textSubtitleBold } from '../mixins';

const Nav = styled.nav`
  border-right: 1px solid ${props => getColor(props, 'mediumGrey')};
  background-color: ${props => getColor(props, 'lightGrey')};
  position: relative;
  height: 100%;
  @media ${deviceMedia.mobile} {
    border-right: none;
  }
`;

interface NavItemProps {
  to: any;
  regularLink?: boolean;
  isMainItem?: boolean;
  isActiveItem?: boolean;
  disabled?: boolean;
}

const getActiveItemStyles = (props: LinkProps): string => `
  background: #fff;
  border-color: ${getColor(props, 'mediumGrey')};
  &:before{
    content: '';
    width: 5px;
    height: 100%;
    background: ${getColor(props, 'headerColor')};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  &:after{
    content: '';
    width: 2px;
    background: #ffffff;
    position: absolute;
    left: ${leftMenuIconContainerWidth}px;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
  i{
    color: ${getColor(props, 'headerColor')};
  }

  svg g[fill] {
    fill: ${getColor(props, 'headerColor')};
  }
  svg path[fill] {
    fill: ${getColor(props, 'headerColor')};
  }

  svg g[stroke^="#"] {
    stroke: ${getColor(props, 'headerColor')};
    fill: none;
  }

  &:first-child{
    border-top: none;
  }
`;

const NAV_ITEM_HEIGHT = '52px';

const NavItem = styled(({ isMainItem, isActiveItem, regularLink, ...rest }) =>
  regularLink ? (
    <a href={rest.to} {...rest}>
      {rest.children}
    </a>
  ) : (
    <Link {...rest} />
  )
)<LinkProps & NavItemProps>`
  text-decoration: none;
  height: ${NAV_ITEM_HEIGHT};
  color: ${props => getColor(props, 'darkGrey')};
  cursor: pointer;
  display: flex;
  align-items: center;
  border-color: transparent;
  border-width: 1px 0;
  border-style: solid;
  position: relative;
  ${textSubtitle};
  ${props => (props.isMainItem ? `overflow: hidden; ${props.isActiveItem ? getActiveItemStyles(props) : ''}` : '')};
  ${({ isActiveItem }) => (isActiveItem ? `${textSubtitleBold};` : '')};
  ${({ disabled }) => (disabled ? 'cursor: not-allowed; opacity: 0.5;' : '')};
`;
NavItem.displayName = 'NavItem';

const NavItemImage = styled.div`
  text-align: center;
  width: ${leftMenuIconContainerWidth}px;
  min-width: ${leftMenuIconContainerWidth}px;
  font-size: 23px;
  i {
    font-weight: normal;
  }
  svg,
  img {
    vertical-align: middle;
    max-width: ${leftMenuIconContainerWidth / 2.5}px;
    max-height: ${leftMenuIconContainerWidth / 2}px;
  }
`;

const NavItemText = styled.div<{ uppercaseTitle?: boolean }>`
  padding: 0 ${leftMenuPaddingForTextContainer}px;
  width: ${leftMenuTextContainerWidth}px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ uppercaseTitle }) => (uppercaseTitle ? 'text-transform: uppercase' : '')};
  @media ${deviceMedia.mobile} {
    width: 100%;
  }
  span {
    min-width: 100px;
    display: inline-block;
  }
`;

const HiddenNavItem = styled.div`
  display: flex;
  height: ${NAV_ITEM_HEIGHT};
`;

const TabsContainer = styled.div<{ isShown: boolean }>`
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  ${props =>
    props.isShown
      ? `width: calc(100% - ${leftMenuIconContainerWidth}px); transition: width 0s 0s ease-in, opacity .1s .0s ease-in; opacity: 1;`
      : 'width: 0; transition: none; opacity: 0;'};
  overflow: hidden;
  border-left: 1px solid ${props => getColor(props, 'mediumGrey')};
`;

interface AttachedProps {
  pages: PagesList;
  settingsLocations: LocationsList;
  activePage?: Page;
  collapsedState: LeftMenuStates;
  hideSupportSearchItem?: boolean;
  rewardsTabStatus: RewardsTabStatus[];
  emCustomerSettings?: EmailMarketingCustomerSettings;
}
interface AttachedAction {
  openMenu: typeof openLeftHandSideMenu;
}
interface OwnProps {
  isTablet?: boolean;
  isMobile?: boolean;
}

type PropTypes = AttachedProps & RouteComponentProps & AttachedAction & OwnProps;

interface IsItemDisabled {
  disabled: boolean;
  item: {
    hint: string;
    path: string;
  };
}

const LeftHandSideMenuComponent = ({
  activePage,
  pages,
  location,
  collapsedState,
  openMenu,
  hideSupportSearchItem,
  rewardsTabStatus,
  emCustomerSettings,
  isMobile,
  settingsLocations
}: PropTypes): React.ReactElement => {
  useEffect(() => {
    if (localStorage.getItem('leftHandSideMenuWasOpened')) {
      openMenu();
    }
  }, [openMenu]);

  const { t } = useTranslation(DEFAULT_NS);

  const onMainLinkClick = (page: Page): void => {
    googleAnalytics.events.leftMenu.selectPage(page.title);
    if (collapsedState === 'openTabsHidden' && Array.isArray(page.subPages) && page.subPages.length) {
      openMenu();
      localStorage.setItem('leftHandSideMenuWasOpened', JSON.stringify(true));
    }
  };

  let pagesSortedByOrder = cloneDeep(pages);
  if (hideSupportSearchItem)
    pagesSortedByOrder = pagesSortedByOrder.filter(page => page.path !== supportSearchPathname);

  const isItemDisabled = (path: string, activePageName: string, pageName: string): IsItemDisabled => {
    if (activePageName === 'rewards_root' || activePageName === 'loyalty_root') {
      const item = [...rewardsTabStatus].find(
        (pageOption: RewardsTabStatus) => pageOption.path === path && pageOption.notAvailable
      );
      if (isMobile && !!item)
        item.hint = t('notAvailableOnMobileTooltip', 'This page must be viewed on a larger screen.');
      return { disabled: !!item, item: item || { hint: '', path: '' } };
    }
    if (emCustomerSettings && activePageName === 'emailMarketing_root') {
      const { customerId } = emCustomerSettings;
      const disabled = customerId === -1 && pageName !== 'emailMarketing_profile';
      return { disabled, item: { hint: '', path: '' } };
    }

    // by default all items are enabled
    return { disabled: false, item: { hint: '', path: '' } };
  };

  const availableSubPages =
    activePage && Array.isArray(activePage.subPages)
      ? activePage.subPages.filter(page => {
          if (page.hiddenIfSingleLocation && settingsLocations.length < 2) return false;
          return !page.hidden;
        })
      : [];

  const hiddenNavItems = useMemo(() => {
    const availableHiddenNavItems = availableSubPages.length - pagesSortedByOrder.length;
    const hiddenItems = [];
    if (availableHiddenNavItems > 0) {
      for (let i = 0; i < availableHiddenNavItems; i++) {
        hiddenItems.push(<HiddenNavItem key={i} />);
      }
      return hiddenItems;
    }
  }, [availableSubPages.length, pagesSortedByOrder.length]);

  const isShown = collapsedState === 'open';
  const menuSubItemsContainerDataTestId = isShown ? 'menu-sub-modules-container' : 'menu-modules-container';

  return (
    <Nav data-test-id="left-hand-side-menu-container">
      {pagesSortedByOrder.map(page => (
        <NavItem
          isMainItem
          isActiveItem={activePage && page.pageId === activePage.pageId}
          regularLink={page.regularLink}
          data-test-id={page.title}
          title={page.title}
          key={page.path}
          to={page.path}
          onClick={() => {
            onMainLinkClick(page);
          }}
        >
          <NavItemImage
            data-test-id={generateDataTestId(page.title, 'icon')}
            dangerouslySetInnerHTML={{ __html: page.leftMenuIcon || '<i class="fab fa-buffer" />' }}
          />
          <NavItemText>{page.title}</NavItemText>
        </NavItem>
      ))}
      {hiddenNavItems}
      {activePage && availableSubPages.length !== 0 && (
        <TabsContainer data-test-id={menuSubItemsContainerDataTestId} isShown={isShown}>
          {availableSubPages.map(page => {
            const path = `${activePage.path}${page.path}`;
            const navItem = isItemDisabled(path, activePage.pageName, page.pageName);
            return (
              <Fragment key={page.path}>
                <NavItem
                  key={page.path}
                  to={!navItem.disabled ? path : ''}
                  isActiveItem={location.pathname.endsWith(page.path)}
                  disabled={navItem.disabled}
                  onClick={(e: Event) => {
                    if (navItem.disabled) e.preventDefault();
                  }}
                >
                  <NavItemText
                    data-test-id={generateDataTestId(page.title, 'sub-item')}
                    uppercaseTitle={page.uppercaseTitle}
                  >
                    <span data-tip={navItem.item.hint} data-for={navItem.item.path}>
                      {page.title}
                    </span>
                  </NavItemText>
                </NavItem>
                {navItem.disabled && <ToolTip id={navItem.item.path} multiline effect="solid" place="bottom" />}
              </Fragment>
            );
          })}
        </TabsContainer>
      )}
    </Nav>
  );
};

export const LeftHandSideMenu = withRouter(
  connect<AttachedProps, AttachedAction, OwnProps, AppState>(
    (appState: AppState) => {
      const activePage = getActivePage(appState);
      const pages = getMenuPagesWithFiltration(appState, 'leftMenu');

      return {
        activePage,
        pages,
        collapsedState: getLeftSideMenuState(appState),
        hideSupportSearchItem: isSupportSearch(appState) && activePage && activePage.path !== supportSearchPathname,
        rewardsTabStatus: getRewardsTabStatus(appState, pages, activePage?.pageName),
        emCustomerSettings: getEmCustomerSettings(appState),
        settingsLocations: getLocations(appState)
      };
    },
    dispatch => bindActionCreators({ openMenu: openLeftHandSideMenu }, dispatch)
  )(LeftHandSideMenuComponent)
);
