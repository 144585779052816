import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getType } from 'typesafe-actions';
import { getMenuPages } from 'store/navigation';
import { AppState } from 'store/rootReducer';
import { take } from '@redux-saga/core/effects';
import { ApiGateway } from 'services/apiGateway';
import i18n from 'i18n';
import { getPagePath } from 'services/navigation';
import { notificationsActions } from 'store/notifications';
import { showApiError } from 'store/errors/utils';
import { emailMarketingSettings } from './actions';
import { EmailMarketingCustomerSettings } from './types';
import { getAcePublicSiteDomain, getConfigName, getEmCustomerSettings, getPublicSitesJWT } from './selectors';
import { decodeEMCustomerSettings, encodeEMCustomerSettings } from './modifiers';
import { isCoreProgramEnabled, isDanubeCompanyWithLoyaltyEnabled, isRewardsEnabled } from '../../rewards/selectors';
import { getLocations } from '../../settings';
import { fetchPromosData, fetchCRMData } from '../../rewards/sagas';
import { PUBLIC_SITES_URLS } from '../../../components/EmailMarketing/Profile/forms/constants';

const escapeUrl = (url: string): string => url.replace(/&/g, '&amp;');

export function* generateJWTSaga(action: ReturnType<typeof emailMarketingSettings.generateJWT.request>) {
  try {
    const state: AppState = yield select();
    const settings = getEmCustomerSettings(state);

    if (settings) {
      const data = yield call(ApiGateway.generateJWT, {
        businessId: settings.locations[0].businessId,
        customerId: settings.customerId,
        isDelayedAction: action.payload
      });
      yield put(emailMarketingSettings.generateJWT.success(data));
    }
  } catch (e) {
    console.log(e);
    yield put(emailMarketingSettings.generateJWT.failure());
  }
}

export function* fetchCountriesData(action: ReturnType<typeof emailMarketingSettings.fetchCountriesData.request>) {
  try {
    const countryID = action.payload;
    const data = yield call(ApiGateway.getCountriesData, countryID);
    yield put(emailMarketingSettings.fetchCountriesData.success(data));
  } catch (e) {
    console.log(e);
    yield put(emailMarketingSettings.fetchCountriesData.failure());
  }
}

export function* emailMarketingSettingsSagas() {
  const fetchCountriesDataActionType = getType(emailMarketingSettings.fetchCountriesData.request);

  yield takeLatest(fetchCountriesDataActionType, fetchCountriesData);

  yield takeLatest(getType(emailMarketingSettings.generateJWT.request), generateJWTSaga);

  yield takeLatest(getType(emailMarketingSettings.fetchCustomerData.request), function* () {
    try {
      const state = yield select();

      const publicFormResponse = yield call(ApiGateway.fetchPublicContactForm);
      const settingsResponse: EmailMarketingCustomerSettings = yield call(ApiGateway.getCustomerSettings);

      const settings: EmailMarketingCustomerSettings = {
        publicContactForm: publicFormResponse,
        ...settingsResponse
      };

      const { pathname } = state.router.location;

      const pages = getMenuPages(state);
      const profilePathname = getPagePath(pages, 'emailMarketing_root', 'emailMarketing_profile');

      if (settings && settings.customerId === -1 && pathname !== profilePathname) {
        yield put(push(profilePathname));
        return;
      }

      yield put(emailMarketingSettings.fetchCustomerData.success(decodeEMCustomerSettings(settings)));
    } catch (e) {
      console.log(e);
      yield put(emailMarketingSettings.fetchCustomerData.failure());
    }
  });

  yield takeLatest(
    getType(emailMarketingSettings.updateCustomerData.request),
    function* (action: ReturnType<typeof emailMarketingSettings.updateCustomerData.request>) {
      try {
        yield call(fetchCRMData);
        yield call(fetchPromosData);

        const state: AppState = yield select();
        const locations = getLocations(state);
        const acePublicSiteDomain = getAcePublicSiteDomain(state);
        const customerSettings = getEmCustomerSettings(state);
        const isDanubeWithLoyalty = isDanubeCompanyWithLoyaltyEnabled(state);
        const isCompanyWithRewards = isRewardsEnabled(state);

        const isCoreLoyaltyEnabled = isCoreProgramEnabled(state);

        const { isFirstTime, isOrganizationNameChanged, isPolicyUrlChanged } = action.payload;

        const actionPayload = action.payload;
        actionPayload.isCoreLoyaltyEnabled = isCoreLoyaltyEnabled;
        actionPayload.isDanubeWithLoyalty = isDanubeWithLoyalty;

        if (isDanubeWithLoyalty && locations && (isFirstTime || isOrganizationNameChanged)) {
          yield call(ApiGateway.updateStoreboarding, {
            merchantNumbers: locations.map(location => location.merchant_sequence_key),
            organizationName: action.payload.organizationName,
            displayName: action.payload.displayName || action.payload.organizationName
          });
        }
        if (isDanubeWithLoyalty && isCoreLoyaltyEnabled && isPolicyUrlChanged) {
          yield put(emailMarketingSettings.generateJWT.request(false));
          yield take([emailMarketingSettings.generateJWT.success, emailMarketingSettings.generateJWT.failure]);
          const configName = yield select(getConfigName);
          const { activeVersionJWT } = yield select(getPublicSitesJWT);
          // eslint-disable-next-line max-len
          actionPayload.termsUrl = escapeUrl(
            `${acePublicSiteDomain}${PUBLIC_SITES_URLS.termsAndConditions}?account=${configName}&jwt=${activeVersionJWT}`
          );
          actionPayload.loyaltyPolicyUrl =
            actionPayload.policyUrl ||
            escapeUrl(
              `${acePublicSiteDomain}${PUBLIC_SITES_URLS.privacyPolicy}?account=${configName}&jwt=${activeVersionJWT}`
            );
          yield call(ApiGateway.updateStoreboarding, {
            merchantNumbers: locations.map(location => location.merchant_sequence_key),
            organizationName: action.payload.organizationName,
            displayName: action.payload.displayName || action.payload.organizationName,
            privacyPolicyUrl: actionPayload.loyaltyPolicyUrl
          });
          yield call(ApiGateway.notifyMPL, {
            termsUrl: actionPayload.termsUrl,
            privacyPolicyUrl: actionPayload.loyaltyPolicyUrl
          });
        }

        const updatedSettings: EmailMarketingCustomerSettings = decodeEMCustomerSettings(
          yield call(ApiGateway.updateCustomerSettings, encodeEMCustomerSettings(actionPayload))
        );
        updatedSettings.isFirstTime = false;
        yield put(emailMarketingSettings.updateCustomerData.success(updatedSettings));

        const pages = getMenuPages(state);

        yield put(
          notificationsActions.addNotification({
            type: 'toast',
            title: i18n.t('settings_SuccessSaveMessageTitle', 'Success!'),
            text: i18n.t('settings_SuccessSaveMessageBody', 'Profile was saved successfully')
          })
        );

        if (isCompanyWithRewards && !isCoreLoyaltyEnabled) {
          yield put(emailMarketingSettings.setGoToRewardsModalState(true));
        } else if (customerSettings && customerSettings.customerId === -1) {
          yield put(push(getPagePath(pages, 'emailMarketing_root', 'emailMarketing_contacts')));
        }
      } catch (e) {
        console.log(e);
        yield put(emailMarketingSettings.updateCustomerData.failure());
      }
    }
  );

  yield takeLatest(
    [
      emailMarketingSettings.updateCustomerData.failure,
      emailMarketingSettings.fetchCustomerData.failure,
      emailMarketingSettings.fetchCustomerData.failure,
      emailMarketingSettings.generateJWT.failure,
      emailMarketingSettings.fetchCountriesData.failure
    ],
    showApiError
  );
}
