import get from 'lodash/get';
import { Location } from 'history';
import {
  ANALYTICS_MODULE_NAME_MAPPING,
  COMPARE_PAGE,
  CUSTOMER_DEMOGRAPHICS_PAGE,
  PROFILE_PAGE,
  REPUTATION_PAGE
} from 'appConstants';
import { Page, PagesList } from 'store/settings/layout';
import { AppState } from 'store/rootReducer';
import { LeftMenuStates } from './types';

export const isTeaser = (page: Page): boolean => page.elements.some(element => element.componentName === page.teaser);
export const getLeftSideMenuState = (appState: AppState): LeftMenuStates => appState.appMenu.leftMenuState;
export const getActivePage = (appState: AppState): Page | undefined => appState.appMenu.activePage;
export const getActiveSubPage = (state: AppState) => {
  const activePage = getActivePage(state);
  if (activePage && Array.isArray(activePage.subPages)) {
    return activePage.subPages.find(page => state.router.location.pathname.endsWith(page.path));
  }
  return activePage;
};

export const isCIPageActive = (activePage?: Page): boolean =>
  activePage ? activePage.path === CUSTOMER_DEMOGRAPHICS_PAGE : false;

export const isReputationPageActive = (activePage?: Page): boolean =>
  !!activePage && activePage.path === REPUTATION_PAGE;

export const isComparePageActive = (availablePages: string[], location: Location, activePage?: Page): boolean =>
  !!(activePage && availablePages.includes(activePage.path) && location.pathname.indexOf(COMPARE_PAGE) !== -1);

export const getActivePageTitle = (appState: AppState): string => {
  const page = getActivePage(appState);
  return page ? page.title : '';
};

export const getHistory = (state: AppState): string[] => state.appMenu.history;
export const getMenuPages = (appState: AppState): PagesList => appState.appMenu.pages;
export const isTopMenuShown = (appState: AppState): boolean => appState.appMenu.topMenuShown;
export const isSupportSearch = (appState: AppState): boolean => appState.appMenu.isSupportSearch;
export const isActivePageTeaser = (appState: AppState): boolean => {
  const activePage = getActivePage(appState);
  return activePage ? isTeaser(activePage) : false;
};
export const getMenuPagesWithFiltration = (state: AppState, menu: 'topMenu' | 'leftMenu'): PagesList =>
  getMenuPages(state).filter(page => {
    // filter pages by DB config
    let shouldBeVisible: boolean;
    switch (menu) {
      case 'leftMenu':
        shouldBeVisible = !page.hiddenForLeftMenu;
        break;
      case 'topMenu':
        shouldBeVisible = !page.hiddenForTopMenu;
        break;
      default:
        shouldBeVisible = true;
    }

    return shouldBeVisible;
  });

export const isOnlyTeasers = (pages: PagesList): boolean => pages.every(isTeaser);
export const getPathname = (state: AppState): string => state.router.location.pathname || '';

const getAvailableCompareCategories = (comparePage: Page): string[] => {
  const comparisonTable = comparePage.elements.find(
    ({ componentName }) => componentName === 'CustomerIntelligenceComparisonTable'
  );
  const features = Object.entries(get(comparisonTable, 'features', {}))
    .filter(([, enabled]) => enabled)
    .map(([name]) => name);

  return comparisonTable ? features : [];
};

export const getDemographicsCategoryRanges = (state: AppState, modulePath: string, pagePath: string): any => {
  const pages = getMenuPages(state);
  const modulePage = pages.find(page => page.path === modulePath);
  const page = modulePage && modulePage.subPages.find(({ path }) => path === pagePath);
  const pageComponentName = pagePath === PROFILE_PAGE ? 'CustomerDemographics' : 'CustomerIntelligenceComparisonTable';
  const component = page?.elements.find(({ componentName }) => componentName === pageComponentName);

  return page ? get(component, 'features', {}) : [];
};

export const getAvailableCompareCategoriesByActivePage = (location: Location, activePage?: Page): string[] => {
  const comparePage = activePage && activePage.subPages.find(({ path }) => location.pathname.indexOf(path) !== -1);
  return comparePage ? getAvailableCompareCategories(comparePage) : [];
};

export const getAvailableCompareCategoriesByModule = (state: AppState, modulePath: string): string[] => {
  const pages = getMenuPages(state);
  const modulePage = pages.find(page => page.path === modulePath);
  const comparePage = modulePage && modulePage.subPages.find(({ path }) => path === COMPARE_PAGE);
  const categories = comparePage && getAvailableCompareCategories(comparePage);

  return categories || [];
};

export const getAdvancedAnalyticsRootPath = (state: AppState) => {
  const allPages = getMenuPages(state);
  const rootPage = allPages.find(el => el.pageName === 'advanced_analytics_root');
  return rootPage?.path;
};

export const getTransactionsFetcherPages = (state: AppState) => {
  const activePage = getActivePage(state);
  const {
    transactionsDataFetcherAnalyticsHomePage,
    transactionsDataFetcherAnalyticsComparePage,
    transactionsDataFetcherAdvancedAnalyticsSalesPage
  } = activePage?.elements.find(el => el.componentName === 'TransactionsDataFetcher')?.features ?? {};
  return {
    analyticsHomePage: transactionsDataFetcherAnalyticsHomePage || 'analytics_dashboard',
    analyticsComparePage: transactionsDataFetcherAnalyticsComparePage || 'analytics_compare',
    advancedAnalyticsSalesPage: transactionsDataFetcherAdvancedAnalyticsSalesPage || 'advanced_analytics_sales'
  };
};

export const getAnalyticsModuleName = (state: AppState): string => {
  const activePage = getActivePage(state);
  const defaultModuleName = ANALYTICS_MODULE_NAME_MAPPING.analytics_root;
  return activePage ? ANALYTICS_MODULE_NAME_MAPPING[activePage?.pageName] || defaultModuleName : defaultModuleName;
};

export const getProgramIds = (state: AppState): Array<string> => state.appMenu.pages.map(page => page.programId ?? '');
