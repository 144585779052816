/* eslint-disable */
import { capitalize } from 'lodash';
import { DASHBOARD } from 'appConstants';

declare let ga: Function;
declare let gtag: Function;

export type LinePickerLabels = 'New' | 'Returning' | 'All';
export type PredictiveAnalyticsLabels =
  | 'Show Sales Forecast'
  | 'Hide Sales Forecast'
  | 'Show Transaction Forecast'
  | 'Hide Transaction Forecast';

export const googleAnalytics = {
  isGAInitialized: false,
  isTagManagerInitialized: false,
  isFirstPageViewSend: false,

  isIdValidID(id: string) {
    return typeof id === 'string' && id.length > 0;
  },

  init(GTMID: string, GAID: string, onetrustScriptID?: string) {
    console.log('Google analytics is starting', GTMID, ' | ', GAID);

    if (!this.isTagManagerInitialized && this.isIdValidID(GTMID)) {
      this.initGoogleTagManager(GTMID);
      this.isTagManagerInitialized = true;
      console.log('Google tag manager is Initialized with ID', GTMID);
    }

    // enable GA 4
    if (!this.isGAInitialized && this.isIdValidID(GAID)) {
      this.initGoogleAnalytics(GAID, onetrustScriptID ? 'denied' : 'granted');
      this.isGAInitialized = true;
      console.log('GA 4 is Initialized with ID', GAID);
      if (onetrustScriptID) {
        this.loadOnetrustScript(onetrustScriptID);
      }
    }
  },

  sendPageView(pagePath: string) {
    if (!pagePath) return;

    if (this.isGAInitialized && !this.isFirstPageViewSend) {
      this.isFirstPageViewSend = true;
      gtag('event', 'page_view', {
        page_path: pagePath
      });
    }
  },

  sendEvent(category: string, action: string, label?: string, value?: string) {
    if (!category || !action) return;

    this.sendPageView(DASHBOARD);

    if (this.isGAInitialized) {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        event_value: value
      });
    }
  },

  events: {
    login: {
      categoryName: 'Login',
      success(source: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Success', source);
      },
      failure() {
        googleAnalytics.sendEvent(this.categoryName, 'Failure');
      }
    },

    portal: {
      categoryName: 'Portal',
      open(source: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Open', source);
      }
    },

    openAccount() {
      googleAnalytics.sendEvent('Account', 'Expand');
    },
    leftMenu: {
      selectPage(pageTitle: string) {
        googleAnalytics.sendEvent('Left Menu', 'Select Page', pageTitle);
      }
    },
    dotsMenu: {
      selectPage(pageTitle: string) {
        googleAnalytics.sendEvent('4 Dots', 'Select Page', pageTitle);
      }
    },

    datePicker: {
      categoryName: 'Date range picker',
      selectRange(name: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Select Range', name);
      }
    },

    locationSelector: {
      categoryName: 'Location selector',
      selectItem() {
        googleAnalytics.sendEvent(this.categoryName, 'Select Item');
      },
      unSelectItem() {
        googleAnalytics.sendEvent(this.categoryName, 'Unselect Item');
      },
      selectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Select All');
      },
      unSelectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Clear Selected');
      },
      expand() {
        googleAnalytics.sendEvent(this.categoryName, 'Expand');
      }
    },

    locationSelectorTestGroup: {
      categoryName: 'Evaluate - Test Group Location selector',
      selectItem() {
        googleAnalytics.sendEvent(this.categoryName, 'Select Item');
      },
      unSelectItem() {
        googleAnalytics.sendEvent(this.categoryName, 'Unselect Item');
      },
      selectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Select All');
      },
      unSelectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Clear Selected');
      },
      expand() {
        googleAnalytics.sendEvent(this.categoryName, 'Expand');
      }
    },

    locationSelectorControlGroup: {
      categoryName: 'Evaluate - Control Group Location selector',
      selectItem() {
        googleAnalytics.sendEvent(this.categoryName, 'Select Item');
      },
      unSelectItem() {
        googleAnalytics.sendEvent(this.categoryName, 'Unselect Item');
      },
      selectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Select All');
      },
      unSelectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Clear Selected');
      },
      expand() {
        googleAnalytics.sendEvent(this.categoryName, 'Expand');
      }
    },

    filter: {
      categoryName: 'Favorite Filter',
      add() {
        googleAnalytics.sendEvent(this.categoryName, 'Add');
      },
      delete() {
        googleAnalytics.sendEvent(this.categoryName, 'Delete');
      },
      apply() {
        googleAnalytics.sendEvent(this.categoryName, 'Apply');
      }
    },

    dayBook: {
      categoryName: 'Daybook',
      add() {
        googleAnalytics.sendEvent(this.categoryName, 'Add');
      },
      delete() {
        googleAnalytics.sendEvent(this.categoryName, 'Delete');
      },
      edit() {
        googleAnalytics.sendEvent(this.categoryName, 'Edit');
      }
    },

    compareLocations: {
      categoryName: 'Locations Comparison',
      getPrefix(gaLabelPrefix: string) {
        return gaLabelPrefix ? gaLabelPrefix + ' ' : '';
      },
      sort(fieldName: string, gaLabelPrefix: string = '') {
        googleAnalytics.sendEvent(this.getPrefix(gaLabelPrefix) + this.categoryName, 'Sort', fieldName);
      },
      changePerformerType(performer: string, type: string, gaLabelPrefix: string = '') {
        googleAnalytics.sendEvent(
          this.getPrefix(gaLabelPrefix) + this.categoryName,
          'Change Performer Type',
          `${this.getPrefix(gaLabelPrefix) + capitalize(type)} ${capitalize(performer)}`
        );
      }
    },

    lineChart: {
      categoryName: 'Line chart',
      changeViewMode(mode: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change view mode', mode);
      }
    },

    spotLights: {
      categoryName: 'Spotlights',
      changeTransactionType(mode: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change transaction type', mode);
      }
    },

    ticketTiers: {
      categoryName: 'Ticket Tiers',
      changeTransactionType(mode: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change transaction type', mode);
      }
    },
    weeklySalesBreakdown: {
      categoryName: 'Weekly Sales Breakdown',
      changeTransactionType(mode: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change transaction type', mode);
      }
    },

    transactionsTiming: {
      categoryName: 'Transaction timings',
      changeTransactionType(mode: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change transaction type', mode);
      }
    },

    salesTiming: {
      categoryName: 'Sales value timings',
      changeTransactionType(mode: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change transaction type', mode);
      }
    },

    download: {
      categoryName: 'Export',
      pdf(eventLabel?: string) {
        googleAnalytics.sendEvent(this.categoryName, 'PDF', eventLabel);
      },
      excel(eventLabel?: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Excel', eventLabel);
      }
    },

    socialReputationRating: {
      categoryName: 'Social Reputation Rating',
      switchTrends(state: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Switch Trends', state);
      }
    },

    customerReach: {
      categoryName: 'Customer Reach',
      changeTab(tabName: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change Tab', tabName);
      }
    },

    teaser: {
      categoryName: 'Teaser',
      play(name?: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Play', name);
      },
      btnClick(name?: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Click on button', name);
      }
    },

    settings: {
      categoryName: 'Settings',
      changeDistanceMetrics(value: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change Distance Metrics', value);
      },
      changeTemperatureMetrics(value: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change Temperature Metrics', value);
      },
      toggleNotifications(value: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Change Notification Settings', value);
      }
    },

    competition: {
      categoryName: 'Competition',
      chooseCompetitor() {
        googleAnalytics.sendEvent(this.categoryName, 'Choose competitor');
      },
      viewReviews() {
        googleAnalytics.sendEvent(this.categoryName, 'View Reviews');
      }
    },

    reviews: {
      categoryName: 'Reviews',
      view() {
        googleAnalytics.sendEvent(this.categoryName, 'View');
      },
      reply() {
        googleAnalytics.sendEvent(this.categoryName, 'Reply');
      },
      sort(field: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Sort', field);
      },
      filter(sites: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Filter', sites);
      }
    },

    contactSupport: {
      categoryName: 'Contact Support',
      linkClick(eventLabel: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Click on link', eventLabel);
      }
    },
    linePicker: {
      categoryName: 'Line Picker',
      expand() {
        googleAnalytics.sendEvent(this.categoryName, 'Expand');
      },
      selectItem(label: LinePickerLabels) {
        googleAnalytics.sendEvent(this.categoryName, 'Select Item', label);
      },
      unSelectItem(label: LinePickerLabels) {
        googleAnalytics.sendEvent(this.categoryName, 'Unselect Item', label);
      },
      selectAll() {
        googleAnalytics.sendEvent(this.categoryName, 'Select All');
      },
      clearSelected() {
        googleAnalytics.sendEvent(this.categoryName, 'Clear Selected');
      }
    },
    predictiveAnalytics: {
      categoryName: 'Predictive Analytics',
      changeViewMode(label: PredictiveAnalyticsLabels) {
        googleAnalytics.sendEvent(this.categoryName, 'Change view mode', label);
      }
    },
    advancedAnalyticsAuthAndDeclineCodes: {
      categoryName: 'Decline Reason Code Trends Picker',
      selectItem(label?: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Select Item', label);
      },
      expand() {
        googleAnalytics.sendEvent(this.categoryName, 'Expand');
      },
      unSelectItem(label?: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Unselect Item', label);
      },
      clearSelected() {
        googleAnalytics.sendEvent(this.categoryName, 'Clear Selected');
      }
    },
    advancedAnalyticsAuthAndDeclineReasonComparison: {
      categoryName: 'Decline Reasons Comparison',
      sort(fieldName: string) {
        googleAnalytics.sendEvent(this.categoryName, 'Sort', fieldName);
      }
    },
    advancedAnalyticsFees: {
      categoryName: 'Fee Trends Over Time',
      switch(label: 'Show' | 'Hide') {
        googleAnalytics.sendEvent(this.categoryName, 'Switch trends', label);
      }
    }
  },

  initGoogleTagManager(id: string) {
    /* eslint-disable */
    //prettier-ignore
    // @ts-ignore
    (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', id);
    /* eslint-enable */
  },

  initGoogleAnalytics(id: string, consent: 'granted' | 'denied') {
    /* eslint-disable */
    // prettier-ignore
    // @ts-ignore
    (function (doc, i) { const headNode = doc.getElementsByTagName('head')[0]; const gaScr = doc.createElement('script'); gaScr.async = true; gaScr.src = `https://www.googletagmanager.com/gtag/js?id=${i}`; const initGaScr = doc.createElement('script'); initGaScr.appendChild(document.createTextNode(`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('consent', 'default', { 'analytics_storage': '${consent}' }); gtag('js', new Date()); gtag('config', '${i}', {'cookie_domain': location.host});`)); headNode.appendChild(gaScr); headNode.appendChild(initGaScr); }(document, id));
  },

  getGaConsentStatus() {
    // @ts-ignore
    return (window.OptanonActiveGroups || '').includes('C0002') ? 'granted' : 'denied';
  },

  loadOnetrustScript(scriptID: string) {
    console.log(`OneTrust is starting, script ID: ${scriptID}`);
    const self = this;
    // @ts-ignore
    window.OptanonWrapper = function OptanonWrapper() {
      gtag('consent', 'update', {
        analytics_storage: self.getGaConsentStatus()
      });
    };
    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', scriptID);
    document.head.appendChild(script);
  }
};
