import { call, put, select, takeLatest, take } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';
import { ApiGateway } from '../../services/apiGateway';
import { rewardsActions } from './actions';
import {
  CRMStatusResponse,
  PromoSettingsResponse,
  PromosResponse,
  SavePromosResponse,
  LoyaltyRewardsResponse,
  LoyaltyTotalResponse,
  LoyaltyMonthlySummaryResponse,
  LoyaltyMethodName,
  UpdateLoyaltyCoreProgramRequest,
  PromoCancellationResponse,
  PromoSettingsResponseAction
} from './types';
import { rewardsFormDataToRequestFormat, responseDataToFormFormat, getLoyaltyApiPath } from './modifiers';
import { AppState } from '../rootReducer';
import {
  getBusinessIdForRewards,
  getCrmClientId,
  getGiftChainId,
  getMIDForRewards,
  getCoreProgramEnabledFlag,
  getCoreProgramId,
  getCoreProgram
} from './selectors';
import {
  getMerchantNumbersMap,
  getPartnerId,
  getSelectedLocations,
  getUserLocations,
  getVersion,
  isAppHubPartnerSelector
} from '../settings';
import {
  EmailMarketingCustomerSettings,
  emailMarketingSettings,
  getAcePublicSiteDomain,
  getConfigName,
  getEmCustomerSettings,
  getPublicSitesJWT,
  isAppHubBroomField,
  isDanubeCompany
} from '../emailMarketing/settings';
import { PUBLIC_SITES_URLS } from '../../components/EmailMarketing/Profile/forms/constants';
import { getIsContactsWithoutLocations } from '../locations';
import { automationActions } from '../emailMarketing/automation';

const escapeUrl = (url: string): string => url.replace(/&/g, '&amp;');

export function* fetchCRMData() {
  try {
    const crmStatusResponse: CRMStatusResponse = yield call(ApiGateway.getCRMRewardsStatus);
    yield put(
      rewardsActions.crmData.success({
        id: crmStatusResponse.crmClientId,
        statuses: crmStatusResponse.emCampaignStatuses
      })
    );
    if (crmStatusResponse.crmClientId) {
      yield put(rewardsActions.promos.request());
    }
  } catch (e) {
    yield put(rewardsActions.crmData.failure(e));
  }
}

export function* fetchPromosData() {
  try {
    const state: AppState = yield select();
    const mid = getMIDForRewards(state);
    const businessId = getBusinessIdForRewards(state);
    const crmClientId = getCrmClientId(state);
    const alxVersion = getVersion(state);
    const promosResponse: PromosResponse = yield call(ApiGateway.getRewardsPromos, {
      mid,
      businessId,
      crmClientId,
      alxVersion
    });

    yield put(rewardsActions.promos.success(promosResponse));
  } catch (e) {
    yield put(rewardsActions.promos.failure(e));
  }
}

export function* RewardsSagas() {
  yield takeLatest(getType(rewardsActions.promos.request), fetchPromosData);
  yield takeLatest(getType(rewardsActions.crmData.request), fetchCRMData);

  yield takeLatest(
    getType(rewardsActions.init),
    function* ({ payload: { fetchActiveCoreProgram } }: ReturnType<typeof rewardsActions.init>) {
      yield put(rewardsActions.crmData.request());
      yield take([rewardsActions.crmData.success, rewardsActions.crmData.failure]);
      const state: AppState = yield select();
      const partnerId = getPartnerId(state);
      if (fetchActiveCoreProgram) {
        yield put(rewardsActions.fetchActiveCoreProgram.request({ partnerId }));
        yield take([rewardsActions.fetchActiveCoreProgram.success, rewardsActions.fetchActiveCoreProgram.failure]);
      }
      const customerId = getCrmClientId(state);

      const coreProgram = getCoreProgram(yield select());
      const queryParamsTuples = coreProgram
        ? ([
            ['discountId', coreProgram?.discountId ?? 'null'],
            ['discountDescription', coreProgram?.discountDescription ?? 'null']
          ] as [string, string][])
        : undefined;
      yield put(
        rewardsActions.loyaltyMonthlySummary.request({
          path: getLoyaltyApiPath(LoyaltyMethodName.monthlySummary, customerId, queryParamsTuples)
        })
      );
      yield put(
        rewardsActions.loyaltyRewards.request({
          path: getLoyaltyApiPath(LoyaltyMethodName.rewards, customerId, queryParamsTuples),
          payload: { discountId: coreProgram?.discountId, discountDescription: coreProgram?.discountDescription }
        })
      );

      yield put(
        rewardsActions.loyaltyTotal.request({
          path: getLoyaltyApiPath(LoyaltyMethodName.total, customerId, queryParamsTuples),
          payload: { discountId: coreProgram?.discountId, discountDescription: coreProgram?.discountDescription }
        })
      );
    }
  );

  yield takeLatest(
    getType(rewardsActions.promoSettings.request),
    function* ({ payload }: ReturnType<typeof rewardsActions.promoSettings.request>) {
      try {
        const state: AppState = yield select();
        const alxVersion = getVersion(state);
        const isAppHubPartner = isAppHubPartnerSelector(state);
        const isEnabled = getCoreProgramEnabledFlag(state);
        const coreProgramId = getCoreProgramId(state);
        const isDanubeAccount = yield select(isDanubeCompany);

        const promoSettingsResponse: PromoSettingsResponse = yield call(ApiGateway.getRewardsPromoSettings, {
          ...payload,
          alxVersion
        });

        const settings = responseDataToFormFormat(
          payload.type,
          {
            ...promoSettingsResponse,
            enabled: isEnabled,
            id: coreProgramId
          },
          isAppHubPartner
        );

        const successPayload: PromoSettingsResponseAction = { type: payload.type, settings };

        if (isDanubeAccount) {
          const promoCancellationResponse: PromoCancellationResponse[] = yield call(
            ApiGateway.getEMAndLoyaltyCancellation
          );
          if (promoCancellationResponse[0] && promoCancellationResponse[0].active) {
            successPayload.cancellationDate = promoCancellationResponse[0].cancellationDate;
          }
        }

        yield put(rewardsActions.promoSettings.success(successPayload));
      } catch (error) {
        yield put(rewardsActions.promoSettings.failure({ error, type: payload.type }));
      }
    }
  );

  yield takeLatest(
    getType(rewardsActions.savePromos.request),
    function* ({ payload }: ReturnType<typeof rewardsActions.savePromos.request>) {
      const isDanubeAccount = yield select(isDanubeCompany);
      const appHubBroomField = yield select(isAppHubBroomField);
      const promoId = yield select(getCoreProgramId);
      let settings: EmailMarketingCustomerSettings = yield select(getEmCustomerSettings);

      try {
        const {
          formData,
          signature,
          isCoreProgramPage = false,
          isAutomationPage = false,
          isDelayedAction = false,
          isActivationDateInFuture = false
        } = payload;

        let activeVersionTermsUrl = '';
        let activeVersionPrivacyPolicyUrl = '';
        let futureVersionTermsUrl = '';
        let futureVersionPrivacyPolicyUrl = '';

        if (isDanubeAccount) {
          yield put(emailMarketingSettings.fetchCustomerData.request());
          yield take([
            emailMarketingSettings.fetchCustomerData.success,
            emailMarketingSettings.fetchCustomerData.failure
          ]);
          settings = yield select(getEmCustomerSettings);
          const acePublicSiteDomain = yield select(getAcePublicSiteDomain);

          yield put(emailMarketingSettings.generateJWT.request(isDelayedAction));
          yield take([emailMarketingSettings.generateJWT.success, emailMarketingSettings.generateJWT.failure]);

          const configName = yield select(getConfigName);
          const { activeVersionJWT, futureVersionJWT } = yield select(getPublicSitesJWT);

          if (activeVersionJWT && futureVersionJWT && acePublicSiteDomain) {
            // eslint-disable-next-line max-len
            activeVersionTermsUrl = `${acePublicSiteDomain}${PUBLIC_SITES_URLS.termsAndConditions}?account=${configName}&jwt=${activeVersionJWT}`;
            // eslint-disable-next-line max-len
            activeVersionPrivacyPolicyUrl = `${acePublicSiteDomain}${PUBLIC_SITES_URLS.privacyPolicy}?account=${configName}&jwt=${activeVersionJWT}`;
            // eslint-disable-next-line max-len
            futureVersionTermsUrl = `${acePublicSiteDomain}${PUBLIC_SITES_URLS.termsAndConditions}?account=${configName}&jwt=${futureVersionJWT}`;
            // eslint-disable-next-line max-len
            futureVersionPrivacyPolicyUrl = `${acePublicSiteDomain}${PUBLIC_SITES_URLS.privacyPolicy}?account=${configName}&jwt=${futureVersionJWT}`;
          }
        }

        const state: AppState = yield select();
        const mid = getMIDForRewards(state);
        const businessId = getBusinessIdForRewards(state);
        const crmClientId = getCrmClientId(state);
        const giftChainId = getGiftChainId(state);
        const alxVersion = getVersion(state);
        const userLocation = getUserLocations(state)[0];
        const selectedLocations = getSelectedLocations(state);
        const merchantNumbersMap = getMerchantNumbersMap(state);
        const includeContactsWithoutLocations = getIsContactsWithoutLocations(state);
        const { timezone } = userLocation;

        const locations = selectedLocations.map(location => merchantNumbersMap[location]);

        const promos = Object.entries(formData).reduce(
          (total, [key, value]) => ({
            ...total,
            [key]: rewardsFormDataToRequestFormat(key, value, promoId, isDanubeAccount, appHubBroomField)
          }),
          {}
        );

        const termsUrl = isDelayedAction ? futureVersionTermsUrl : activeVersionTermsUrl;
        const privacyPolicyUrl =
          settings && settings.policyUrl
            ? settings.policyUrl
            : isDelayedAction
            ? futureVersionPrivacyPolicyUrl
            : activeVersionPrivacyPolicyUrl;

        const newPayload = {
          crmClientId,
          giftChainId,
          mid,
          businessId,
          alxVersion,
          promos,
          signature,
          isDelayedAction,
          isActivationDateInFuture,
          termsUrl: isCoreProgramPage ? termsUrl : undefined,
          privacyPolicyUrl: isCoreProgramPage ? privacyPolicyUrl : undefined
        };

        const savePromosResponse: SavePromosResponse = yield call(ApiGateway.saveRewardsPromos, newPayload);

        if (isDanubeAccount) {
          if (isDelayedAction && !isActivationDateInFuture) {
            // Send notification to CRM ONLY if Core Loyalty program is ACTIVE
            const coreLoyaltyUpdatePayload: UpdateLoyaltyCoreProgramRequest = {
              termsUrl: escapeUrl(termsUrl),
              privacyPolicyUrl: escapeUrl(privacyPolicyUrl),
              locations,
              includeContactsWithoutLocations,
              timezone
            };

            yield call(ApiGateway.sendCRMTemplatesForCoreLoyaltyUpdate, coreLoyaltyUpdatePayload);
          } else if (isCoreProgramPage && !isActivationDateInFuture) {
            // MPL Notification call for Loyalty Core Program change
            yield call(ApiGateway.notifyMPL, {
              termsUrl: activeVersionTermsUrl,
              privacyPolicyUrl: activeVersionPrivacyPolicyUrl
            });
          }
        }

        yield put(rewardsActions.savePromos.success(savePromosResponse));
        if (isCoreProgramPage) yield put(rewardsActions.toggleCoreSuccessModal(true));

        if (isAutomationPage) {
          yield put(rewardsActions.toggleRewardsAutomationSuccessModal(true));
          yield put(automationActions.getAutomationCoreStatus.success(true));
        }
        yield put(rewardsActions.crmData.request());
        yield put(rewardsActions.promos.request());
      } catch (e) {
        yield put(rewardsActions.savePromos.failure(e));
      }
    }
  );

  yield takeLatest(
    getType(rewardsActions.loyaltyMonthlySummary.request),
    function* ({ payload }: ReturnType<typeof rewardsActions.loyaltyMonthlySummary.request>) {
      try {
        const loyaltyMonthlySummaryResponse: LoyaltyMonthlySummaryResponse = yield call(
          ApiGateway.getRewardsLoyaltyData,
          payload
        );
        yield put(rewardsActions.loyaltyMonthlySummary.success(loyaltyMonthlySummaryResponse));
      } catch (e) {
        yield put(rewardsActions.loyaltyMonthlySummary.failure(e));
      }
    }
  );

  yield takeLatest(
    getType(rewardsActions.loyaltyRewards.request),
    function* ({ payload }: ReturnType<typeof rewardsActions.loyaltyRewards.request>) {
      try {
        const loyaltyRewardsResponse: LoyaltyRewardsResponse = yield call(ApiGateway.getRewardsLoyaltyData, payload);
        yield put(rewardsActions.loyaltyRewards.success(loyaltyRewardsResponse));
      } catch (e) {
        yield put(rewardsActions.loyaltyRewards.failure(e));
      }
    }
  );

  yield takeLatest(
    getType(rewardsActions.loyaltyTotal.request),
    function* ({ payload }: ReturnType<typeof rewardsActions.loyaltyTotal.request>) {
      try {
        const loyaltyTotalResponse: LoyaltyTotalResponse = yield call(ApiGateway.getRewardsLoyaltyData, payload);
        yield put(rewardsActions.loyaltyTotal.success(loyaltyTotalResponse));
      } catch (e) {
        yield put(rewardsActions.loyaltyTotal.failure(e));
      }
    }
  );
  yield takeLatest(
    getType(rewardsActions.fetchActiveCoreProgram.request),
    function* ({ payload }: ReturnType<typeof rewardsActions.loyaltyTotal.request>) {
      try {
        const res = yield call(ApiGateway.getRewardsActiveCoreProgram, payload);
        yield put(rewardsActions.fetchActiveCoreProgram.success(res));
      } catch (e) {
        yield put(rewardsActions.fetchActiveCoreProgram.failure(e));
      }
    }
  );
}
