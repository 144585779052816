import moment from 'moment';
import { sortBy, isArray } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { push } from 'connected-react-router';
import { BaseURLs, fetchApplications, ApplicationSource } from '@app-tray-ui/react-wrapper';

import { getActivePage, isOnlyTeasers, isActivePageTeaser, receivePages, getPathname } from 'store/navigation';
import { ApiGateway } from 'services/apiGateway';
import { googleAnalytics } from 'services/googleAnalytics';
import { localizationService } from 'services/localization';
import { NO_PAGE_FOUND_PATH } from 'appConstants';
import { getPdfCookies, pdfActions } from 'store/pdf';
import { pendoInit } from 'services/pendoInit';
import { variablesService } from 'services/variables';
import {
  DigestStatusConfig,
  MyAccountSuccessResponse,
  NotificationSettingsSuccess,
  SettingsConfig,
  SettingsState,
  UpdateSettingsRequest,
  UserLocation
} from './types';
import { settingsActions } from './actions';
import { setDateRange } from '../appDateRangePicker';
import { filterDependantSubPages, translatePagesName, getValidPages } from './modifiers';
import {
  getDigestEmail,
  getDistanceMetrics,
  getLocationsWithEnabledDigest,
  getSettingsLanguage,
  getSettingsReturningFrequency,
  getTemperatureMetrics,
  getUIVersion,
  getUserLocations,
  isFeatureOrComponentEnabled
} from './selectors';
import { AppState } from '../rootReducer';
import { locationsActions } from '../locations';
import { getConfirmedTermsFromLocalStorage, termsActions } from '../terms';
import { getAppHubTray } from '../branding';

const { info, updateMyAccount, updateNotifications } = settingsActions;

export function* settingsSagas() {
  yield takeLatest(
    getType(updateNotifications.request),
    function* ({ payload }: ReturnType<typeof updateNotifications.request>) {
      try {
        const { digest, alert } = payload;
        const state: AppState = yield select();

        const successPayload: NotificationSettingsSuccess = {
          digest: yield call(ApiGateway.setDigestData, {
            mskList: digest.locations,
            digestEnabled: digest.enabled,
            digestEmail: getDigestEmail(state)
          }),
          alerts: yield call(ApiGateway.setAutoAlertStatus, alert)
        };

        yield put(updateNotifications.success(successPayload));
      } catch (e) {
        yield put(updateNotifications.failure());
      }
    }
  );

  yield takeLatest(
    getType(updateMyAccount.request),
    function* ({ payload }: ReturnType<typeof updateMyAccount.request>) {
      try {
        const { email, distanceMetrics, temperatureMetrics, returningFrequency } = payload;
        const state: AppState = yield select();

        const currentEmail = getDigestEmail(state);
        const currentDistanceMetrics = getDistanceMetrics(state);
        const currentTemperatureMetrics = getTemperatureMetrics(state);
        const currentReturningFrequency = getSettingsReturningFrequency(state);

        const successPayload: MyAccountSuccessResponse = {};

        if (currentEmail !== email) {
          const enabledMerchants = getLocationsWithEnabledDigest(state);
          const setDigestRequest = {
            mskList: enabledMerchants,
            digestEmail: email,
            digestEnabled: !!enabledMerchants.length
          };
          const digestResponse: DigestStatusConfig = yield call(ApiGateway.setDigestData, setDigestRequest);
          Object.assign(successPayload, { digestResponse });
        }

        if (
          currentDistanceMetrics !== distanceMetrics ||
          currentReturningFrequency !== returningFrequency ||
          currentTemperatureMetrics !== temperatureMetrics
        ) {
          const updateSettingsRequest: UpdateSettingsRequest = {
            msk: getUserLocations(state).map(el => el.merchant_sequence_key),
            value: {
              language: getSettingsLanguage(state),
              returningFrequency,
              distanceMetrics,
              temperatureMetrics
            }
          };
          const settingsResponse: SettingsConfig = yield call(ApiGateway.updateSettings, updateSettingsRequest);
          Object.assign(successPayload, { settingsResponse });
        }

        yield put(updateMyAccount.success(successPayload));
      } catch (e) {
        yield put(updateMyAccount.failure());
      }
    }
  );

  yield takeLatest(getType(info.request), function* gen() {
    try {
      const state = yield select();
      const pdfCookies = getPdfCookies();
      yield put(pdfActions.getDataFromCookies(pdfCookies));
      const response = yield call(ApiGateway.fetchSettingsData);

      variablesService.provide(response.variables);

      if (response.variables.pendoKey) {
        pendoInit(response.variables.pendoKey, response.config?.name, response.visitorToken);
      }

      localizationService.init(response.config.availableCurrencies, response.config.localizationSettings);

      const versions = isArray(response.versions) ? response.versions : [];

      const uiVersion = { name: 'ui', version: getUIVersion(state) };

      [...versions, uiVersion].forEach((service: { name: string; version: string }) => {
        const { name, version } = service;
        const tag = document.createElement('meta');
        tag.setAttribute('name', `${name}-version`);
        tag.setAttribute('content', version);
        document.head.appendChild(tag);
      });

      if (!response.isTestToken) {
        googleAnalytics.init(
          response.config.googleTagManagerId,
          response.config.googleAnalyticsGA4Id,
          response.config.oneTrustID
        );
      }

      const locations =
        Array.isArray(response.selectedLocations) && response.selectedLocations.length > 0
          ? response.selectedLocations
          : response.locations.map((el: UserLocation) => el.merchant_sequence_key);

      const translatedPages = translatePagesName(response.pages);
      const validPages = getValidPages(response.config.name, translatedPages);
      const filteredPages = filterDependantSubPages(
        yield select(),
        validPages,
        Object.values(response.merchantPrograms),
        locations
      );
      const sortedPagesByOrder = sortBy(filteredPages, 'order');

      if (sortedPagesByOrder.length && sortedPagesByOrder[0].regularLink) {
        window.location.replace(sortedPagesByOrder[0].path);
        return;
      }

      yield put(receivePages(sortedPagesByOrder, response.merchantPages));
      yield put(info.success(response));

      const activePage = getActivePage(yield select());

      const allowedLocations = activePage
        ? locations.filter((el: string) => (response.merchantPages[el] || []).includes(activePage.pageId))
        : locations;

      yield put(locationsActions.select(isActivePageTeaser(yield select()) ? locations : allowedLocations));

      // eslint-disable-next-line no-restricted-syntax
      for (const type of getConfirmedTermsFromLocalStorage(locations)) {
        yield put(termsActions.confirmTerms(type));
      }

      if (!pdfCookies.pdfMode) {
        try {
          const appState: AppState = yield select();
          const isLineChartPredictiveDataEnabled = isFeatureOrComponentEnabled(
            appState,
            'analytics_dashboard',
            'LineChart',
            'predictiveData'
          );
          const defaultDateRange = Number((response as SettingsState).config.defaultDateRange);
          const defaultPredictiveDateRange = Number((response as SettingsState).config.defaultPredictiveDateRange);
          const startDate = moment().subtract(defaultDateRange, 'days');
          const endDate = isLineChartPredictiveDataEnabled
            ? moment().add(defaultPredictiveDateRange - 1, 'days')
            : moment().subtract(1, 'days');
          const endPredictiveDate = isLineChartPredictiveDataEnabled ? endDate : null;
          if (defaultDateRange > 0 && startDate.isValid() && endDate.isValid()) {
            yield put(setDateRange(startDate, endDate, endPredictiveDate));
          }
        } catch (e) {
          console.log('Unable to read date range from config. Will be used default - 14 days');
        }
      }

      if (localStorage.getItem('googleAnalyticsFirstLoginFlag')) {
        googleAnalytics.events.login.success(getPathname(state));
        yield call(ApiGateway.dwhLogin);
        localStorage.removeItem('googleAnalyticsFirstLoginFlag');
      }
    } catch (e) {
      googleAnalytics.events.login.failure();
      yield put(info.failure());
      console.error(e);
    }
  });

  yield takeLatest(getType(info.success), function* ({ payload }: ReturnType<typeof info.success>) {
    const { pages, config, isMocked } = payload;

    const isProgramExists = pages.length < 1 || isOnlyTeasers(pages) || isMocked;
    const shouldRedirect = !config.noPagesStrategy || config.noPagesStrategy === 'pageNotFound';

    if (isProgramExists && shouldRedirect) {
      yield put(push(NO_PAGE_FOUND_PATH));
    }
  });

  yield takeLatest(
    getType(settingsActions.getAppHubTrayItems.request),
    function* ({ payload: { jwt } }: ReturnType<typeof settingsActions.getAppHubTrayItems.request>) {
      try {
        const appHubTray = yield select(getAppHubTray);
        if (!appHubTray) throw new Error("appHubTray baseUrlEnv doesn't set up");

        const appHubTrayItems = yield call(
          fetchApplications,
          BaseURLs[appHubTray.baseUrlEnv as keyof typeof BaseURLs],
          jwt,
          ApplicationSource.SMB
        );

        yield put(settingsActions.getAppHubTrayItems.success(appHubTrayItems));
      } catch (e) {
        console.error('error during fetching app hub applications', e);
        yield put(settingsActions.getAppHubTrayItems.failure('Error during fetching app hub applications'));
      }
    }
  );
}
