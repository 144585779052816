export const localeMap: Record<string, string> = {
  'en-US': 'en-US',
  en_US: 'en-US',
  'fr-CA': 'fr-CA',
  fr_CA: 'fr-CA',
  'en-GB': 'en-GB',
  en_GB: 'en-GB',
  'de-AT': 'de-AT',
  de_AT: 'de-AT',
  'cs-CZ': 'cs',
  cs_CZ: 'cs',
  'ro-RO': 'ro',
  ro_RO: 'ro',
  'sk-SK': 'sk',
  sk_SK: 'sk',
  'de-DE': 'de',
  de_DE: 'de',
  'hu-HU': 'hu',
  hu_HU: 'hu'
};
