import { Segment, SegmentsTranslations } from './types';

export default (i18n: SegmentsTranslations) => {
  interface Segments {
    [key: string]: Segment;
  }

  const generateSegments = (from: number, to: number, i18n: SegmentsTranslations) => {
    const segments: Segments = {};

    for (let i = from; i <= to; i++) {
      segments[i] = {
        name: i18n[`name${i}`],
        description: i18n[`description${i}`],
        isDefault: false,
        value: 0,
        image: require(`./icons/${i}.png`)
      };
    }

    return segments;
  };

  const setIsDefaultToTrueForSegments = (ids: string[] | number[], segments: Segments) => {
    ids.forEach((id: string | number) => {
      segments[id].isDefault = true;
    });
  };

  const segments = {
    ...generateSegments(1, 23, i18n),
    ...generateSegments(39, 40, i18n),
    ...generateSegments(43, 44, i18n)
  };

  setIsDefaultToTrueForSegments([5, 16, 18, 22, 39], segments);

  return segments;
};
